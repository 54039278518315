<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div v-if="config.type === 'cancelOrder'">
        <div class="modal_text">
          <img src="../../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否确定取消此订单?</span>
        </div>
        <div class="modal_text modal_reset">
          订单编号:{{ config.record.orderCode }}
        </div>
        <div class="modal_text modal_reset">
          订购信息:{{ config.record.orderContent }}
        </div>
      </div>
      <div v-if="config.type === 'upload'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="订单编号">
            {{ config.record.orderCode }}
          </a-form-model-item>
          <a-form-model-item label="支付方式">
            <span v-if="config.record.payMethod === 1">线下支付</span>
            <span v-if="config.record.payMethod === 2">线上支付</span>
          </a-form-model-item>
          <a-form-model-item label="支付金额">
            <span v-if="config.record.orderPayable" style="color: #fc8600"
              >￥{{ config.record.orderPayable }}</span
            >
          </a-form-model-item>
          <a-form-model-item label="付款账户" prop="account">
            <a-input :maxLength="37" v-model="form.account" />
          </a-form-model-item>
          <a-form-model-item label="支付银行" prop="bank">
            <a-input :maxLength="30" v-model="form.bank" />
          </a-form-model-item>
          <a-form-model-item label="上传付款凭证" prop="payment">
            <span class="notice_text">
              支持格式：PNG、JPG或JPEG或PDF，单个文件不超10M
            </span>
            <div class="upload_content">
              <UploadTag
                :config="uploadConfig"
                @getImageUrl="getPaymentUrl"
              ></UploadTag>
            </div>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="revisitResult">
            <a-textarea
              :maxLength="100"
              v-model="form.remarks"
              placeholder="请输入"
              @change="remarksOnchange"
              :auto-size="{ minRows: 8, maxRows: 8 }"
            />
            <span style="position: absolute; right: 10px; bottom: -10px"
              >已输入{{ detLength }}/100</span
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="config.type === 'editOrder'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="订单金额" prop="money">
            <a-input-number
              placeholder="请输入"
              class="input_width"
              v-model="form.money"
              :step="0.01"
              :min="0"
              @change="onOrderChange"
              :precision="2"
              :formatter="limitDecimals"
              :parser="limitDecimals"
            />
          </a-form-model-item>
          <a-form-model-item label="订购期限（月）" prop="time">
            <a-select v-model="form.time" class="input_width">
              <a-select-option
                v-for="(item, index) in 60"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { orderManageApi } from "@/api/orderManage.js";
import UploadTag from "../../../components/uploadTag.vue";

export default {
  components: {
    UploadTag,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验银行账号
    let validatorAccount = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款账户不能为空"));
      } else if (!/^[1-9]\d{9,37}$/.test(value.replace(/\s*/g, "")) && value) {
        callback(new Error("付款账户格式不正确"));
      } else {
        callback();
      }
    };
    return {
      detLength: 0,
      labelCol2: { span: 2 },
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      payVoucher: "",
      confirmLoading: false,
      codeList: [],
      uploadConfig: {
        barrelType: "secret",
        uploadType: "default",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitControl: false, //是否限制照片墙数量
        // limitLength: 1,
        fileList: [], //文件列表
        limitType: ["image/jpeg", "image/png", "image/jpg", "application/pdf"],
      },

      form: {
        account: "",
        bank: "",
        payment: "",
        remarks: "",
        money: "",
        time: "",
      },

      rules: {
        money: [
          { required: true, message: "订单金额不能为空", trigger: "blur" },
        ],
        time: [
          { required: true, message: "订购期限不能为空", trigger: "blur" },
        ],
        payment: [
          { required: true, message: "付款凭证不能为空", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "支付银行不能为空", trigger: "blur" },
        ],
        account: [
          {
            required: true,
            trigger: "blur",
            validator: validatorAccount,
          },
        ],
      },
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {
    ["form.account"](val) {
      this.$nextTick(() => {
        this.form.account = val.replace(/\D/g, "").replace(/....(?!$)/g, "$& ");
      });
    },
  },
  methods: {
    limitDecimals(val) {
      return val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },
    initModal() {
      if (
        this.config.type === "upload" &&
        this.config.record?.storageInfoState
      ) {
        this.form.account = this.config.record.payAccount;
        this.form.bank = this.config.record.payBank;
        this.form.payment = this.config.record.payVoucher;
        this.form.remarks = this.config.record.payRemark;
        this.$nextTick(() => {
          this.detLength = this.form.remarks.length;
        });
        console.log("父组件传值", this.form.payment)
        this.form.payment.forEach((v) => {
          this.uploadConfig.fileList.push({
            name: v,
            uid: v,
            status: "done",
            url: v,
          });
        });
      }
    },
    onOrderChange(val) {
      this.form.money = val;
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.form.remarks.length;
      });
    },
    //获取上传付款凭证地址
    getPaymentUrl(val, fileList) {
      console.log("返回的结果",fileList)
      let temp = [];
      fileList.forEach((v) => {
        console.log("xxxx",v.url)
        temp.push(v.name);
      });
      console.log("存入的是啥",temp)
      this.form.payment = temp;
      this.uploadConfig.fileList = fileList;
      // this.form.payment = temp.join(",");
    },
    // //保存批量凭证详情
    editOrder() {
      this.confirmLoading = true;
      let params = {
        operationStatus: 3,
        orderCode: this.config.record.orderCode,
        orderId: this.config.record.orderId,
        orderRemark: this.config.record.orderRemark,
      };
      orderManageApi.editOrder(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
          this.confirmLoading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    changeOrder() {
      this.confirmLoading = true;
      let params = {
        termOfValidity: this.form.time,
        orderPrice: this.form.money,
        orderId: this.config.record.orderId,
      };
      orderManageApi.changeOrder(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
          this.confirmLoading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.confirmLoading = false;
        }
      });
    },
    handleOk() {
      if (this.config.type === "upload") {
        console.log("存入之前",this.form.account)
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let info = {
              payMethod: this.config.record.payMethod,
              payAmount: this.config.record.orderPayable,
              payBank: this.form.bank,
              payAccount: this.form.account.replace(/\s*/g, ""),
              //  payAccount: this.form.account,
              payRemark: this.form.remarks,
              payVoucher: this.form.payment,
            };
            console.log("存入的本地数据",info)
            window.sessionStorage.setItem("orderInfo", JSON.stringify(info));
            this.$emit("handleOk");
          }
        });
      } else if (this.config.type === "editOrder") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.changeOrder();
          }
        });
      } else {
        this.editOrder();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 340px;
}
.upload_content {
  min-height: 46px;
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  // font-weight: 900;
}
.add_positon {
  position: relative;
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  flex-grow: 1;
}
.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 36px;
  font-weight: 200;

  margin-bottom: 20px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 40px;
  padding-top: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
</style>