<template>
  <div>
    <a-spin :spinning="spinning">
      <div class="order_header">订单编号：{{ orderCode }}</div>
      <div class="order_step_contant">
        <div class="order_step">
          <a-steps progress-dot :current="orderStatusCop">
            <a-step title="提交订单" :description="orderTime" />
            <a-step
              title="付款"
              v-if="
                orderStatus != 4 ||
                (orderStatus === 4 && payVoucher.length != 0)
              "
              :description="payTime"
            />
            <a-step
              title="订单审核"
              v-if="orderStatus != 4"
              :description="inspectTime"
            />
            <a-step
              title="资源初始化"
              v-if="orderStatus != 6"
              :description="inspectTime"
            />
            <a-step
              title="资源初始化失败"
              v-if="orderStatus == 6"
              :description="inspectTime"
            />
            <a-step
              title="订单完成"
              v-if="orderStatus != 4"
              :description="completeTime"
            />
            <a-step
              title="取消订单"
              status="error"
              v-if="orderStatus === 4"
              :description="cancelTime"
            />
          </a-steps>
        </div>
      </div>
      <div class="order_middel">
        <div>订单详情</div>
        <div>
          <a-button @click="goBack" size="small" style="padding-top: 2px">
            返回订单列表
          </a-button>
          <a-button
            v-if="orderStatus === 3"
            size="small"
            style="padding-top: 2px; margin-left: 20px"
            type="primary"
          >
            下载离线安装包
          </a-button>
          <a-button
            v-if="orderStatus === 1 || orderStatus === 2"
            size="small"
            @click="openModel('cancelOrder')"
            style="padding-top: 2px; margin-left: 20px"
          >
            取消订单
          </a-button>
        </div>
      </div>
      <div class="order_contant">
        <div class="tips">
          <div class="tips_left"></div>
          <div class="tips_right">订单信息</div>
        </div>
        <div class="train">
          <div class="carriage">
            <div class="seat">
              <div>订单编号:</div>
              <div>{{ orderCode }}</div>
            </div>

            <div class="seat">
              <div>订购账号:</div>
              <div>{{ orderAccount }}</div>
            </div>
            <div class="seat">
              <div>订购有效期:</div>
              <div>
                {{ orderTermOfValidity }}
                <span v-if="orderTermOfValidity">月</span>
                <!-- {{
                  orderTermOfValidity == "-1" ? "永久有效" : orderTermOfValidity
                }}
                <span v-if="orderType === 1 && orderTermOfValidity != '-1'">{{
                  appExpirationTime
                }}</span> -->
              </div>
            </div>
          </div>
          <div class="carriage">
            <div class="seat">
              <div>订购时间:</div>
              <div>{{ orderTime }}</div>
            </div>

            <div class="seat">
              <div>订购状态:</div>
              <div>
                <span v-if="orderStatus">
                  {{ orderList[orderStatus - 1].name }}</span
                >
              </div>
            </div>
            <div class="seat">
              <div>备注:</div>
              <div>
                {{ orderRemark }}
              </div>
            </div>
          </div>
          <div class="carriage">
            <div class="seat">
              <div>订单租户</div>
              <div>{{ tenantName }}</div>
            </div>
            <div class="seat">
              <div>支付时间:</div>
              <div>{{ payTime }}</div>
            </div>

            <div class="seat">
              <div>订购内容:</div>
              <div class="order_content">{{ orderContent }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_contant">
        <div class="tips">
          <div class="tips_left"></div>
          <div class="tips_right">费用信息</div>
        </div>
        <div class="train">
          <div class="carriage">
            <div class="seat">
              <div>订单金额:</div>
              <div style="color: #fc8600; font-weight: 900">
                <span v-if="orderPrice">￥{{ orderPrice }}</span>
              </div>
            </div>

            <div class="seat">
              <div>优惠劵:</div>
              <div style="font-weight: 900">-¥{{ orderCoupon }}</div>
            </div>
            <div class="seat">
              <div>应付款金额:</div>
              <div style="color: #fc8600; font-weight: 900">
                <span v-if="orderPayable"> ￥{{ orderPayable }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_contant">
        <div class="tips">
          <div class="tips_left"></div>
          <div class="tips_right">订单付款信息</div>

          <a-button
            v-if="orderStatus === 1 || orderStatus === 2"
            @click="openModel('upload')"
            class="uploadPay"
            >上传付款凭证</a-button
          >
        </div>
        <div class="train">
          <div class="carriage">
            <div class="seat">
              <div>支付方式:</div>
              <div>
                <span v-if="payMethod">{{
                  styleList[payMethod - 1].name
                }}</span>
              </div>
            </div>

            <div class="seat">
              <div>支付金额:</div>
              <div style="color: #fc8600; font-weight: 900">
                <span v-if="payAmount">￥{{ payAmount }}</span>
              </div>
            </div>
          </div>
          <div class="carriage">
            <div class="seat">
              <div>付款账户:</div>
              <div>{{ payAccount }}</div>
            </div>

            <div class="seat">
              <div>备注:</div>
              <div>
                {{ payRemark }}
              </div>
            </div>
          </div>
          <div class="carriage">
            <div class="seat">
              <div>支付银行:</div>
              <div>{{ payBank }}</div>
            </div>

            <div class="seat">
              <div>付款凭证:</div>
              <div>
                <div
                  class="image_contain"
                  v-if="payVoucher && payVoucher.length > 0"
                >
                  <div
                    v-for="(item, index) in payVoucher"
                    style="margin-right: 10px"
                    :key="index"
                  >
                    <!-- <img
                      style="width: 104px; height: 104px"
                      :src="item"
                      alt=""
                        // item.substring(item.lastIndexOf("/") + 1)
                    /> -->
                    <div class="load_bottom">
                      <a @click="download(item)">{{ item }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_contant">
        <div class="tips">
          <div class="tips_left"></div>
          <div class="tips_right">订单处理备注</div>
        </div>
        <div class="mark_contant">
          <a-textarea
            class="mark_text"
            :maxLength="500"
            v-model="remarks"
            placeholder="请输入"
            @change="remarksOnchange"
            :auto-size="{ minRows: 6 }"
          />
          <span style="position: absolute; left: 600px; bottom: 4px"
            >已输入{{ detLength }}/500</span
          >
        </div>
      </div>

      <div class="bottom">
        <a-button class="bottom_button" @click="orderKeep" shape="round"
          >保存</a-button
        >
        <a-button
          type="primary"
          v-if="orderStatus === 6"
          :loading="loading"
          class="reload_button"
          @click="reload"
          shape="round"
          >重新初始化</a-button
        >
        <a-button
          class="bottom_button"
          v-if="orderStatus !== 3 && orderStatus !== 5 && orderStatus !== 6"
          type="primary"
          shape="round"
          @click="openModel('editOrder')"
          >修改订单</a-button
        >
        <a-button
          class="bottom_button"
          v-if="orderStatus === 2"
          type="primary"
          shape="round"
          @click="passOrder"
          >审核通过</a-button
        >
        <a-button
          class="bottom_button"
          v-if="orderStatus === 3"
          @click="noPassOrder"
          shape="round"
          >取消审核</a-button
        >
      </div>
    </a-spin>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import ModelTag from "./model.vue";
import { orderManageApi } from "@/api/orderManage.js";
import { GetSecretObsClient, getAkAndSk } from "../../../utils/obs";

export default {
  components: {
    ModelTag,
  },
  data() {
    return {
      loading: false,
      orderStatusCop: null,
      storageInfoState: false,
      payVoucher: [],
      remarks: "",
      tempState: "",
      spinning: false,
      detLength: 0,
      orderId: null,
      orderCode: null, //订单编号
      orderAccount: "",
      orderTermOfValidity: "",
      orderTime: "",
      orderStatus: "",
      orderRemark: "",
      orderType: null,
      appExpirationTime: "",
      payTime: "",
      completeTime: "",
      orderContent: "",
      orderPrice: "",
      orderCoupon: "",
      orderPayable: "",
      payMethod: "",
      payAmount: "",
      payAccount: "",
      payRemark: "",
      payBank: "",
      tenantName: "",
      inspectTime: "",
      cancelTime: "",
      orderList: [
        { name: "待付款", id: 1 },
        { name: "待审核", id: 2 },
        { name: "订单完成", id: 3 },
        { name: "已取消", id: 4 },
        { name: "资源初始化", id: 5 },
        { name: "资源初始化失败", id: 6 },
      ],
      styleList: [{ name: "线下支付", id: 1 }],
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
        status: false,
        num: 0,
      },
    };
  },
  created() {
    this.getOrderId();
  },
  beforeDestroy() {
    if (this.storageInfoState) {
      sessionStorage.removeItem("orderInfo");
    }
  },
  methods: {
    reload() {
      this.loading = true;
      this.spinning = true;
      let params = {
        orderId: this.orderId,
      };

      orderManageApi.reload(params).then((res) => {
        if (res.success) {
          this.spinning = false;
          this.loading = false;
          this.$message.success("资源初始化中，请稍后刷新页面");
          this.getInnerDetail();
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
          this.loading = false;
        }
      });
    },
    //保存按钮
    orderKeep() {
      this.spinning = true;
      // if (
      //    (this.orderStatus === 1 && this.storageInfoState) ||
      //   this.orderStatus != 1
      // ) {
      let params = {
        operationStatus: 1,
        orderCode: this.orderCode,
        orderId: this.orderId,
        orderRemark: this.remarks,
      };
      if (this.orderStatus === 1 || this.orderStatus === 2) {
        let s = {
          payAccount: this.payAccount
            ? this.payAccount.replace(/\s*/g, "")
            : "",
          payAmount: this.payAmount,
          payBank: this.payBank,
          payRemark: this.payRemark,
          payMethod: 1,
          payVoucher: this.payVoucher.join(","),
          // payVoucher: this.payVoucher,
        };
        params = { ...params, ...s };
      }
      orderManageApi.editOrder(params).then((res) => {
        if (res.success) {
          this.spinning = false;
          this.$message.success("操作成功！");
          this.getInnerDetail();
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
      // } else {
      //   this.$message.warn("请先上传付款凭证！");
      //   this.spinning = false;
      // }
    },
    //通过按钮
    passOrder() {
      this.spinning = true;
      let params = {
        operationStatus: 2,
        orderCode: this.orderCode,
        orderId: this.orderId,
        orderRemark: this.remarks,
      };
      let s = {
        payAccount: this.payAccount.replace(/\s*/g, ""),
        payAmount: this.payAmount,
        payBank: this.payBank,
        payRemark: this.payRemark,
        payMethod: 1,
        payVoucher: this.payVoucher.join(","),
        // payVoucher: this.payVoucher,
      };
      params = { ...params, ...s };
      orderManageApi.editOrder(params).then((res) => {
        if (res.success) {
          this.spinning = false;
          this.$message.success("操作成功！");
          this.getInnerDetail();
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    //取消按钮
    noPassOrder() {
      this.spinning = true;
      let params = {
        operationStatus: 4,
        orderCode: this.orderCode,
        orderId: this.orderId,
        orderRemark: this.remarks,
      };

      orderManageApi.editOrder(params).then((res) => {
        if (res.success) {
          this.spinning = false;
          this.$message.success("操作成功！");
          this.getInnerDetail();
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    goBack() {
      this.$router.push("/orderManage");
    },
    //获取临时令牌
    async setSKandAK(fileName) {
      let ak = await getAkAndSk(
        "/cloud-platform-backend-facade/front-api/v1/common/object/akAndSk"
      );
      if (ak) {
        //缓存至本地临时仓库
        window.sessionStorage.setItem("securitytoken", ak.securitytoken);
        window.sessionStorage.setItem("secret", ak.secret);
        window.sessionStorage.setItem("access", ak.access);
        window.sessionStorage.setItem("expires_at", ak.expires_at);
        //初始化传进来的文件列表
        this.handleDown(fileName);
      } else {
        this.$message.error("临时密钥获取失败");
      }
    },
    async handleDown(fileName) {
      console.log("下载的名称", fileName);
      let sk = {
        securitytoken: window.sessionStorage.getItem("securitytoken"),
        secret: window.sessionStorage.getItem("secret"),
        access: window.sessionStorage.getItem("access"),
      };
      let tempfile = await GetSecretObsClient(
        fileName,
        "datarun-cloud-sec",
        sk
      );
      let link = document.createElement("a");
      fetch(tempfile.fileAddress)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          link.download = tempfile.fileName;
          document.body.appendChild(link);
          link.click();
        });
    },
    async download(item) {
      let fileName = item;
      // let fileName = item.substring(item.lastIndexOf("com") + 4); //文件名
      const expires = sessionStorage.getItem("expires_at");
      const securityToken = sessionStorage.getItem("securitytoken");
      //是否有临时token等信息
      if (expires && securityToken) {
        if (new Date(expires).getTime() < Date.now()) {
          //过期，请求临时token
          await this.setSKandAK(fileName);
        } else {
          //未过期，初始化
          this.handleDown(fileName);
        }
      } else {
        //为满足，请求临时令牌
        await this.setSKandAK(fileName);
      }
    },
    getOrderId() {
      this.orderId = this.$route.params.id;
      this.$nextTick(() => {
        this.getInnerDetail();
      });
    },
    getInnerDetail() {
      this.spinning = true;
      let params = {
        orderId: this.orderId,
      };
      orderManageApi.getInnerDetail(params).then((res) => {
        if (res.success) {
          this.orderCode = res.data.orderCode;
          this.orderAccount = res.data.orderAccount;
          this.orderTermOfValidity = res.data.orderTermOfValidity;
          this.orderTime = res.data.orderTime;
          this.orderStatus = res.data.orderStatus;
          this.orderRemark = res.data.orderNote;
          this.payTime = res.data.payTime;
          this.orderContent = res.data.orderContent;
          this.orderPrice = res.data.orderPrice;
          this.orderCoupon = res.data.orderCoupon;
          this.orderType = res.data.orderType;
          this.orderPayable = res.data.orderPayable;
          this.payMethod = res.data.payMethod;
          this.payAmount = res.data.payAmount;
          this.tenantName = res.data.tenantName;
          this.payAccount = res.data.payAccount
            ? res.data.payAccount
                .replace(/\D/g, "")
                .replace(/....(?!$)/g, "$& ")
            : res.data.payAccount;
          this.payRemark = res.data.payRemark;
          this.payBank = res.data.payBank;
          this.completeTime = res.data.completeTime;
          this.appExpirationTime = res.data.appExpirationTime;
          this.payVoucher = res.data.payVoucher
            ? res.data.payVoucher.split(",")
            : [];
          console.log("订单初始化的数据", this.payVoucher);
          this.remarks = res.data.orderRemark;
          if (this.orderStatus === 4 && this.payVoucher.length > 0) {
            this.orderStatusCop = 3;
          } else if (this.orderStatus === 4 && this.payVoucher == 0) {
            this.orderStatusCop = 2;
          } else if (this.orderStatus === 5) {
            this.orderStatusCop = 3;
          } else if (this.orderStatus === 3) {
            this.orderStatusCop = 4;
          } else if (this.orderStatus === 6) {
            this.orderStatusCop = 3;
          } else {
            this.orderStatusCop = res.data.orderStatus;
          }
          this.cancelTime = res.data.cancelTime;
          this.inspectTime = res.data.inspectTime;
          this.$nextTick(() => {
            if (this.remarks) {
              this.detLength = this.remarks.length;
            }
          });
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.remarks.length;
      });
    },
    //调用弹框事件
    openModel(type, record = null) {
      console.log(record);
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "cancelOrder") {
        this.modelConfig.record = {
          orderCode: this.orderCode,
          orderContent: this.orderContent,
          payMethod: 1,
          orderRemark: this.remarks,
          orderId: this.orderId,
        };
        this.modelConfig.title = "取消订单";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
      } else if (type === "editOrder") {
        this.modelConfig.title = "修改订单";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.record = {
          // orderCode: this.orderCode,
          // orderContent: this.orderContent,
          // payMethod: 1,
          // orderRemark: this.remarks,
          orderId: this.orderId,
        };
      } else {
        this.modelConfig.record = {
          orderCode: this.orderCode,
          orderPayable: this.orderPayable,
          payMethod: 1,
        };
        if (this.storageInfoState || this.orderStatus === 2) {
          let s = {
            payAccount: this.payAccount.replace(/\s*/g, ""),
            payRemark: this.payRemark,
            payBank: this.payBank,
            payVoucher: this.payVoucher,
            storageInfoState: true,
          };
          this.modelConfig.record = {
            ...this.modelConfig.record,
            ...s,
          };
        }
        this.modelConfig.title = "上传付款凭证";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "1000px";
      }
    },
    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      if (this.modelConfig.type === "upload") {
        this.$message.success("提交成功！");
        this.storageInfoState = true;
        let s = JSON.parse(sessionStorage.getItem("orderInfo"));
        this.payMethod = s.payMethod;
        this.payAmount = s.payAmount;
        this.payAccount = s.payAccount
          ? s.payAccount.replace(/\D/g, "").replace(/....(?!$)/g, "$& ")
          : s.payAccount;
        this.payRemark = s.payRemark;
        this.payBank = s.payBank;
        this.payVoucher = s.payVoucher;
      } else {
        this.$message.success("操作成功！");
        this.getInnerDetail();
        this.storageInfoState = false;
      }
      this.modelConfig.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.order_header {
  height: 46px;
  background-color: #f0f2f5;
  line-height: 46px;
  font-size: 20px;
  min-width: 100%;
   overflow: hidden;
}
.order_step_contant {
  height: 100px;
  overflow: hidden;
   min-width: 100%;
}
.order_step {
  width: 800px;
  margin: auto;
  margin-top: 20px;
}
.right_button {
  text-align: right;
}
.order_middel {
  height: 46px;
  background-color: #f0f2f5;
  line-height: 46px;
  font-size: 20px;
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.order_contant {
  padding: 0 20px;
}
.tips {
  display: flex;
  height: 24px;
  margin-bottom: 20px;
}
.tips_left {
  width: 6px;
  height: 24px;
  background: rgba(24, 143, 255, 0.596);
}
.tips_right {
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  font-size: 18px;
}
.train {
  display: flex;
  justify-content: space-between;
}
.carriage {
  width: 30%;
}
.seat {
  display: flex;
  margin-bottom: 20px;
}
.seat > :last-child {
  margin-left: 12px;
  flex: 1;
  padding-right: 8px;
}
.seat > :first-child {
  // width: 80px;
  color: #999999;
  min-width: 80px;
  // background-color: aqua;
  
}
.mark_contant {
  position: relative;
  overflow: hidden;
}
.mark_text {
  width: 700px;
}
.bottom {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.bottom_button {
  width: 100px;
  padding-top: 3px;
  margin-left: 20px;
}
.reload_button{
   padding-top: 3px;
  margin-left: 20px;
}
.uploadPay {
  height: 30px;
  margin-top: -4px;
  margin-left: 10px;
}
.load_bottom {
  height: 20px;
  width: 104px;
  // background-color: aqua;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}
.order_content{
  // background-color: yellow;
  // max-width:30% ;
  word-break:break-all

}
.image_contain {
  // display: flex;
  // flex-wrap: wrap;
}
</style>